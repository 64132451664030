import { MeetHttpHelper, NO_DATA_MESSAGE } from '../../../utils/HttpHelper';

const GetMeetDistinctAthletesWithPersonId = (meetId, eventCompetitionGenderTypeId, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const meetIdForUrl = meetId ? encodeURIComponent(meetId) : 'NaN';
    const eventCompetitionGenderTypeIdForUrl = eventCompetitionGenderTypeId ? encodeURIComponent(eventCompetitionGenderTypeId) : 'NaN';
    const url = `/meet/${meetIdForUrl}/${eventCompetitionGenderTypeIdForUrl}/athleteWithPersonId`;
    MeetHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const arrayAsOptions = [];
          for (let i = 0; i < arrayData.length; i++) {
            const element = arrayData[i];
            if (arrayData.findIndex(item => item.competitor === element.competitor) === i) {
              arrayAsOptions.push({ id: element.personId, name: element.competitor });
            }
          }
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            arrayAsOptions,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetMeetDistinctAthletesWithPersonId;

