import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './MyTimeStandardsDetailValidation';

import useTimeStandardData from '../../../state/timeStandard/UseTimeStandardData';
import useLogoFileData from '../../../state/logoFile/UseLogoFileData';

import useTimeStandardTypeData from '../../../../common/state/timeStandardType/UseTimeStandardTypeData';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

import NavLinks from '../NavLinks';

const FILE_TYPE_ERROR_MSG = 'File type not permitted. Please provide a (*.pdf), (*.jpg), or (*.png) file.';
const SAVING_ERROR_MSG = 'The last action is still saving. Please try again.';

const useMyTimeStandardDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({ tryRedirect: false, setInitialState: true, error: '' });
  const { timeStandardState, clearObjData, getTimeStandard, postTimeStandard, putTimeStandard } = useTimeStandardData();
  const { timeStandardTypeState } = useTimeStandardTypeData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { logoFileUploadState, postLogoFile, getLogoFile, getFileNameFromSavedUploadUrl, getFileNameFromUrl } = useLogoFileData();
  const { errorState, formState, handleSubmit, onValueTextPairChanged, onFormValueChanged,
    setFormData, setIsDirty, isSubmitting } = useForm(getInitialFormState, submitFormCallback, validate);
  const [fileState, setFileState] = useState({ logoFileUrl: '', logoFileName: '' });
  const [removeFileModalState, setRemoveFileModalState] = useState(getInitialRemoveFileModalState());

  const onUploadLogoClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, error: '' });
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_JPG ||
        fileType === Constants.FILE_TYPE_PNG) {
        postLogoFile(file);
      }
      else {
        setState({ ...state, error: FILE_TYPE_ERROR_MSG });
      }
    }
  };

  const onRemoveLogoClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
    if (timeStandardState.isSaving === true || logoFileUploadState.isObjLoading === true) {
      setState({ ...state, error: SAVING_ERROR_MSG });
    }
    else {
      setFileState({ ...fileState, logoFileUrl: '', logoFileName: '' });
      setState({ ...state, error: '' });
      if (timeStandardState.objData.timeStandardId > 0) {
        const url = null;
        putTimeStandard(timeStandardState.objData.timeStandardId, editTimeStandardObj(url));
      }
    }
  };

  const onOpenRemoveFileModalClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRemoveFileModalState({ ...removeFileModalState, fileName: fileState.logoFileName, displayPopUp: true });
  };

  const onRemoveFileModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
  };

  const onDownloadUploadedFile = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    showUploadedFile(fileState.logoFileUrl);
  };

  const createTimeStandardObj = () => {
    let timeStandardCopy = JSON.parse(JSON.stringify(timeStandardState.objData));
    timeStandardCopy = {
      ...timeStandardCopy,
      standardName: formState.standardName.trim(),
      standardDescription: formState.standardDescription.trim(),
      orgUnitId: selectOrgUnitState.orgUnitId,
      timeStandardTypeId: formState.timeStandardTypeId,
      effectiveDate: formState.effectiveDate,
      expirationDate: formState.expirationDate,
      sortOrder: parseInt(formState.sortOrder),
      timeStandardAgeGroup: timeStandardCopy.timeStandardAgeGroup ?
        timeStandardCopy.timeStandardAgeGroup : [],
      timeStandardLogoUrl: fileState.logoFileUrl ? fileState.logoFileUrl :
        timeStandardCopy.timeStandardLogoUrl ? timeStandardCopy.timeStandardLogoUrl : null
    }

    return timeStandardCopy;
  }

  const editTimeStandardObj = (uploadUrl) => {
    let timeStandardCopy = JSON.parse(JSON.stringify(timeStandardState.objData));
    timeStandardCopy = {
      ...timeStandardCopy,
      timeStandardLogoUrl: uploadUrl
    }

    return timeStandardCopy;
  };

  function submitFormCallback(formState) {
    //Edit
    if (formState.timeStandardId > 0) {
      putTimeStandard(timeStandardState.objData.timeStandardId, createTimeStandardObj());
    }
    //Add
    else {
      postTimeStandard(createTimeStandardObj());
    }
    setState({ ...state, tryRedirect: true });
  }

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    clearObjData();
    navigate(NavLinks.MY_TIME_STANDARDS_SEARCH);
  }

  useEffect(() => {
    //Edit
    if (location.state && location.state.timeStandardId > 0) {
      setFormData({
        ...formState,
        timeStandardId: location.state.timeStandardId
      });
      getTimeStandard(location.state.timeStandardId);
    }
    //Add
    else {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  useEffect(() => {
    if (Object.keys(timeStandardState.objData).length > 0
      && location.state && location.state.timeStandardId > 0) {
      setFileState({
        ...state,
        logoFileUrl: timeStandardState.objData.timeStandardLogoUrl ?
          timeStandardState.objData.timeStandardLogoUrl : '',
        logoFileName: timeStandardState.objData.timeStandardLogoUrl ?
          getFileNameFromUrl(timeStandardState.objData.timeStandardLogoUrl)
          : '',
      });
      setFormData({
        ...formState,
        timeStandardId: timeStandardState.objData.timeStandardId,
        orgUnit: [{ id: timeStandardState.objData.orgUnitId, name: timeStandardState.objData.orgUnit.orgUnitName }],
        standardName: timeStandardState.objData.standardName || '',
        standardDescription: timeStandardState.objData.standardDescription || '',
        timeStandardTypeId: timeStandardState.objData.timeStandardTypeId || Constants.DEFAULT_ID,
        timeStandardTypeName: timeStandardState.objData.timeStandardType?.typeName || '',
        sortOrder: timeStandardState.objData.sortOrder >= 0 ? timeStandardState.objData.sortOrder : '',
        effectiveDate: formatDate(timeStandardState.objData.effectiveDate) || Constants.BLANK_DATE_STRING,
        expirationDate: formatDate(timeStandardState.objData.expirationDate) || Constants.BLANK_DATE_STRING
      });
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStandardState.objData])

  useEffect(() => {
    if (logoFileUploadState.isObjLoading === false && logoFileUploadState.isObjLoaded === true &&
      Object.keys(logoFileUploadState.objData).length > 0) {
      setFileState({ ...fileState, logoFileUrl: logoFileUploadState.objData.uploadUrl, logoFileName: getFileNameFromSavedUploadUrl() });
      if (formState.timeStandardId > 0) {
        putTimeStandard(timeStandardState.objData.timeStandardId, editTimeStandardObj(logoFileUploadState.objData.uploadUrl));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoFileUploadState]);

  useEffect(() => {
    if (state.tryRedirect === true && timeStandardState.isObjLoaded === true && isSubmitting === false) {
      navigate(NavLinks.MY_TIME_STANDARDS_AGE_GROUP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, timeStandardState.isObjLoaded, isSubmitting])

  async function showUploadedFile(fileUrl) {
    if (fileUrl) {
      const getFileResult = await getLogoFile(fileUrl);

      if (getFileResult) {
        if (getFileResult.objData?.blobFile?.size > 0) {
          var file = window.URL.createObjectURL(getFileResult.objData?.blobFile);
          const link = document.createElement('a');

          link.href = file;
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
    }
  }

  function getInitialFormState() {
    return {
      timeStandardId: Constants.DEFAULT_ID,
      orgUnitName: '',
      orgUnit: [],
      standardName: '',
      standardDescription: '',
      timeStandardTypeId: Constants.DEFAULT_ID,
      timeStandardTypeName: '',
      sortOrder: '',
      effectiveDate: Constants.BLANK_DATE_STRING,
      expirationDate: Constants.BLANK_DATE_STRING
    };
  }

  function getInitialRemoveFileModalState() {
    return {
      displayPopUp: false,
      fileName: '',
      modalTitle: 'Remove Uploaded File?'
    };
  }

  return {
    state,
    fileState,
    logoFileUploadState,
    timeStandardState,
    timeStandardTypeState,
    selectOrgUnitState,
    formState,
    errorState,
    handleSubmit,
    onValueTextPairChanged,
    onFormValueChanged,
    onCancelClicked,
    onUploadLogoClicked,
    removeFileModalState,
    onRemoveLogoClicked,
    onOpenRemoveFileModalClicked,
    onRemoveFileModalCanceled,
    onDownloadUploadedFile
  }
};

export default useMyTimeStandardDetail;
