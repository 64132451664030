import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './TimeStandardsDetailValidation';

import useTimeStandardData from '../../../state/timeStandard/UseTimeStandardData';
import useLogoFileData from '../../../state/logoFile/UseLogoFileData';

import useTimeStandardTypeData from '../../../../common/state/timeStandardType/UseTimeStandardTypeData';
import useOrgUnitData from '../../../../common/state/orgUnit/UseOrgUnitData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';
import { TimesHttpHelper, NO_DATA_MESSAGE } from '../../../../common/utils/HttpHelper';

import NavLinks from '../NavLinks';

const FILE_TYPE_ERROR_MSG = 'File type not permitted. Please provide a (*.pdf), (*.jpg), or (*.png) file.';
const SAVING_ERROR_MSG = 'The last action is still saving. Please try again.';

const useTimeStandardDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({ tryRedirect: false, setInitialState: true, error: '' });
  const { timeStandardState, clearObjData, getTimeStandard, postTimeStandard, putTimeStandard } = useTimeStandardData();
  const { timeStandardTypeState } = useTimeStandardTypeData();
  const { orgUnitState } = useOrgUnitData();
  const { logoFileUploadState, postLogoFile, getLogoFile, getFileNameFromSavedUploadUrl, getFileNameFromUrl } = useLogoFileData();
  const { errorState, formState, handleSubmit, onValueTextPairChanged, onFormValueChanged, setFormState,
    setFormData, setIsDirty, isSubmitting } = useForm(getInitialFormState, submitFormCallback, validate);
  const [fileState, setFileState] = useState({ logoFileUrl: '', logoFileName: '' });
  const [removeFileModalState, setRemoveFileModalState] = useState(getInitialRemoveFileModalState());

  const onUploadLogoClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setState({ ...state, error: '' });
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_JPG ||
        fileType === Constants.FILE_TYPE_PNG) {
        postLogoFile(file);
      }
      else {
        setState({ ...state, error: FILE_TYPE_ERROR_MSG });
      }
    }
  };

  const onRemoveLogoClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
    if (timeStandardState.isSaving === true || logoFileUploadState.isObjLoading === true) {
      setState({ ...state, error: SAVING_ERROR_MSG });
    }
    else {
      setFileState({ ...fileState, logoFileUrl: '', logoFileName: '' });
      setState({ ...state, error: '' });
      if (timeStandardState.objData.timeStandardId > 0) {
        const url = null;
        putTimeStandard(timeStandardState.objData.timeStandardId, editTimeStandardObj(url));
      }
    }
  };

  const onOpenRemoveFileModalClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRemoveFileModalState({ ...removeFileModalState, fileName: fileState.logoFileName, displayPopUp: true });
  };

  const onRemoveFileModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
  };

  const onDownloadUploadedFile = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    showUploadedFile(fileState.logoFileUrl);
  };

  const createTimeStandardObj = () => {
    let timeStandardCopy = JSON.parse(JSON.stringify(timeStandardState.objData));
    timeStandardCopy = {
      ...timeStandardCopy,
      standardName: formState.standardName.trim(),
      standardDescription: formState.standardDescription.trim(),
      orgUnitId: formState.orgUnit[0].id,
      timeStandardTypeId: formState.timeStandardTypeId,
      effectiveDate: formState.effectiveDate,
      expirationDate: formState.expirationDate,
      sortOrder: parseInt(formState.sortOrder),
      timeStandardAgeGroup: timeStandardCopy.timeStandardAgeGroup ?
        timeStandardCopy.timeStandardAgeGroup : [],
      timeStandardLogoUrl: fileState.logoFileUrl ? fileState.logoFileUrl :
        timeStandardCopy.timeStandardLogoUrl ? timeStandardCopy.timeStandardLogoUrl : null
    }

    return timeStandardCopy;
  };

  const editTimeStandardObj = (uploadUrl) => {
    let timeStandardCopy = JSON.parse(JSON.stringify(timeStandardState.objData));
    timeStandardCopy = {
      ...timeStandardCopy,
      timeStandardLogoUrl: uploadUrl
    }

    return timeStandardCopy;
  };

  function submitFormCallback(formState) {
    //Edit
    if (formState.timeStandardId > 0) {
      putTimeStandard(timeStandardState.objData.timeStandardId, createTimeStandardObj());
    }
    //Add
    else {
      postTimeStandard(createTimeStandardObj());
    }
    setState({ ...state, tryRedirect: true });
  }

  const onOrgUnitChanged = (newValue) => {
    setFormState({
      ...formState,
      orgUnit: newValue
    });
    setIsDirty(true);
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    clearObjData();
    navigate(NavLinks.TIME_STANDARDS_SEARCH);
  }

  useEffect(() => {
    //Edit
    if (location.state && location.state.timeStandardId > 0) {
      setFormData({
        ...formState,
        timeStandardId: location.state.timeStandardId
      });
      getTimeStandard(location.state.timeStandardId);
    }
    //Add
    else {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  useEffect(() => {
    if (Object.keys(timeStandardState.objData).length > 0
      && location.state && location.state.timeStandardId > 0) {
      setFileState({
        ...state,
        logoFileUrl: timeStandardState.objData.timeStandardLogoUrl ?
          timeStandardState.objData.timeStandardLogoUrl : '',
        logoFileName: timeStandardState.objData.timeStandardLogoUrl ?
          getFileNameFromUrl(timeStandardState.objData.timeStandardLogoUrl)
          : '',
      });
      setFormData({
        ...formState,
        timeStandardId: timeStandardState.objData.timeStandardId,
        orgUnit: [{ id: timeStandardState.objData.orgUnitId, name: timeStandardState.objData.orgUnit.orgUnitName }],
        standardName: timeStandardState.objData.standardName || '',
        standardDescription: timeStandardState.objData.standardDescription || '',
        timeStandardTypeId: timeStandardState.objData.timeStandardTypeId || Constants.DEFAULT_ID,
        timeStandardTypeName: timeStandardState.objData.timeStandardType?.typeName || '',
        sortOrder: timeStandardState.objData.sortOrder >= 0 ? timeStandardState.objData.sortOrder : '',
        effectiveDate: formatDate(timeStandardState.objData.effectiveDate) || Constants.BLANK_DATE_STRING,
        expirationDate: formatDate(timeStandardState.objData.expirationDate) || Constants.BLANK_DATE_STRING
      });
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStandardState.objData])

  useEffect(() => {
    // Handle repopulating time standard type on the form
    if (Object.keys(timeStandardState.objData).length > 0
      && location.state && location.state.timeStandardId > 0 && state.setInitialState === true) {
      if (state.setInitialState === true && timeStandardTypeState.isArrayLoaded === true && timeStandardTypeState.isOptionsLoaded === true && orgUnitState.isArrayLoaded === true) {
        if ((timeStandardState.objData.orgUnit && formState.orgUnit[0]?.orgUnitId === timeStandardState.objData.orgUnit[0]?.id && timeStandardTypeState.orgUnitId === formState.orgUnit[0]?.id) ||
          (timeStandardState.objData.orgUnit === undefined && formState.orgUnit.length === 0 && timeStandardTypeState.orgUnitId === '')) {
          setState({ ...state, setInitialState: false });
          setFormData({
            ...formState,
            timeStandardTypeId: timeStandardState.objData.timeStandardTypeId || Constants.DEFAULT_ID,
            timeStandardTypeName: timeStandardState.objData.timeStandardType?.typeName || '',
          });
          setIsDirty(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStandardState.objData, formState.orgUnit, timeStandardTypeState, orgUnitState.isArrayLoaded]);

  useEffect(() => {
    if (logoFileUploadState.isObjLoading === false && logoFileUploadState.isObjLoaded === true &&
      Object.keys(logoFileUploadState.objData).length > 0) {
      setFileState({ ...fileState, logoFileUrl: logoFileUploadState.objData.uploadUrl, logoFileName: getFileNameFromSavedUploadUrl() });
      if (formState.timeStandardId > 0) {
        putTimeStandard(timeStandardState.objData.timeStandardId, editTimeStandardObj(logoFileUploadState.objData.uploadUrl));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoFileUploadState]);

  useEffect(() => {
    if (state.tryRedirect === true && timeStandardState.isObjLoaded === true && isSubmitting === false) {
      navigate(NavLinks.TIME_STANDARDS_AGE_GROUP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, timeStandardState.isObjLoaded, isSubmitting])

  async function showUploadedFile(fileUrl) {
    if (fileUrl) {
      const getFileResult = await getLogoFile(fileUrl);

      if (getFileResult) {
        if (getFileResult.objData?.blobFile?.size > 0) {
          var file = window.URL.createObjectURL(getFileResult.objData?.blobFile);
          const link = document.createElement('a');

          link.href = file;
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
    }
  }

  function getInitialFormState() {
    return {
      timeStandardId: Constants.DEFAULT_ID,
      orgUnitName: '',
      orgUnit: [],
      standardName: '',
      standardDescription: '',
      timeStandardTypeId: Constants.DEFAULT_ID,
      timeStandardTypeName: '',
      sortOrder: '',
      effectiveDate: Constants.BLANK_DATE_STRING,
      expirationDate: Constants.BLANK_DATE_STRING
    };
  }

  function getInitialRemoveFileModalState() {
    return {
      displayPopUp: false,
      fileName: '',
      modalTitle: 'Remove Uploaded File?'
    };
  }

  return {
    state,
    fileState,
    logoFileUploadState,
    timeStandardState,
    orgUnitState,
    timeStandardTypeState,
    formState,
    errorState,
    handleSubmit,
    onValueTextPairChanged,
    onFormValueChanged,
    onOrgUnitChanged,
    onCancelClicked,
    onUploadLogoClicked,
    removeFileModalState,
    onRemoveLogoClicked,
    onOpenRemoveFileModalClicked,
    onRemoveFileModalCanceled,
    onDownloadUploadedFile
  }
};

export default useTimeStandardDetail;
