import { Fragment } from 'react';

import useRelayLegs from './UseRelayLegs';
import RelayLegsGrid from './RelayLegsGrid';
import RelayLegsForm from './RelayLegsForm';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const RelayLegs = () => {
  const {
    timeState,
    relayLegsState,
    legErrorState,
    meetDistinctAthletesWithPersonIdState,
    onRowChanged,
    onSaveButtonClicked,
    onCancelButtonClicked,
    onSubmitFormCallback,
    setRelayLegsState
  } = useRelayLegs();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Relay Legs</Headings.H3>
        </div>
      </div>
      <RelayLegsForm 
        timeState={timeState} 
        relayLegsState={relayLegsState}
        legErrorState={legErrorState} 
        meetEventAthletes={meetDistinctAthletesWithPersonIdState.arrayAsOptions}
        onRowChanged={onRowChanged} 
        onSubmitFormCallback={onSubmitFormCallback}
        onSaveButtonClicked={onSaveButtonClicked}
        onCancelButtonClicked={onCancelButtonClicked}
        setRelayLegsState={setRelayLegsState} />
      
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={timeState.isSaving} />

      <PopUpModal 
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVED_MSG}
        displayPopUp={meetDistinctAthletesWithPersonIdState.isArrayLoading} />
    </Fragment>);
};

export default RelayLegs;