import useRelayLegsForm from "./UseRelayLegsForm"
import RelayLegsGrid from "./RelayLegsGrid"
import { Fragment } from "react";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import global from "../../../../common/components/GlobalStyle.module.css";

const RelayLegsForm = ({ 
  timeState, 
  relayLegsState, 
  legErrorState, 
  meetEventAthletes, 
  onRowChanged, 
  onSubmitFormCallback, 
  onSaveButtonClicked, 
  onCancelButtonClicked,
  setRelayLegsState
}) => {
  const { formState, options, handleSubmit, onValueTextPairChanged, onFormValueChanged } = useRelayLegsForm(onSubmitFormCallback, meetEventAthletes, relayLegsState, setRelayLegsState);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12">
          {timeState.objData.personId !== null ?
            <p className={global.HeaderText}>See 'Splits' for Individual Time split information</p> :
            
            <RelayLegsGrid 
              formState={formState}
              timeState={timeState} 
              relayLegsState={relayLegsState} 
              options={options}
              onRowChanged={onRowChanged}
              onValueTextPairChanged={onValueTextPairChanged}
              onFormValueChanged={onFormValueChanged} />
            
          }
        </div>
      </div>
      {legErrorState.isError === true &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{legErrorState.errorMessage}</p>
          </div>
        </div>}
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          {timeState.objData.personId === null ?             
          <Fragment>
            <PrimaryButton type="button" onClick={onSaveButtonClicked} >Save</PrimaryButton>&nbsp;
    
          </Fragment>
            : <Fragment />
          }
          <SecondaryButton type="button" onClick={onCancelButtonClicked}>Back</SecondaryButton>
        </div>
      </div>
    </form>
  );
}

export default RelayLegsForm;