import Input from '../../../../common/components/inputs/Input';
import MeetTimesCompetitorCombobox from '../../../../common/components/comboboxes/meetTimesCompetitorCombobox/MeetTimesCompetitorCombobox';

import useLegsRow from './UseLegsRow';

import global from '../../../../common/components/GlobalStyle.module.css';
import Combobox from '../../../../common/components/comboboxes/Combobox'

const LegRow = ({i, formState, rowState, options, onRowChanged, onValueTextPairChanged, onFormValueChanged }) => {
  const { onTimeChanged } = useLegsRow(rowState, onRowChanged); 

  return (
    <tr>
      <td style={{ width: 100 }}>
        {rowState.legNumber}
      </td>
      {/* <td>{rowState.competitor}</td> */}
      <td>
        <Combobox
          name={`personId${i}`}
          valueToMatch={formState[`personName${i}`] || ''}
          items={options}
          onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, `personId${i}`, newValueLabel, `personName${i}`); }} />
      </td>
      <td>{rowState.eventDescription}</td>
      <td style={{ width: 100 }}>
        <Input
          label=""
          name={`legTime${i}`}
          value={formState[`legTime${i}`] || ''}
          onChange={(value) => { onFormValueChanged(`legTime${i}`, value) }} />
      </td>
    </tr>
  );
};

const RelayLegsLargeGrid = ({ timeState, relayLegsState, formState, options, onRowChanged, onValueTextPairChanged, onFormValueChanged }) => {
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Relay Leg</th>
          <th>Competitor</th>
          <th>Event</th>
          <th>Time</th>
        </tr>
      </thead>
      <tbody>
        {
          timeState.isArrayLoading === true || relayLegsState.relayLegs === undefined
            ? (<tr>
              <td colSpan="4">Loading...</td>
            </tr>)
            :
            relayLegsState.relayLegs.length > 0 ?
              relayLegsState.relayLegs.map((rowState, i) =>
                <LegRow
                  i={i+1}
                  formState={formState}
                  options={options}
                  onRowChanged={onRowChanged}
                  onValueTextPairChanged={onValueTextPairChanged}
                  onFormValueChanged={onFormValueChanged}
                  rowState={rowState}
                  key={i} />)
              :
              (<tr>
                <td colSpan="4">No Splits</td>
              </tr>)
        }
      </tbody>
    </table>
  );
};

export default RelayLegsLargeGrid;