export const localValidate = (formState) => {
  const errors = {};

  return errors;
}

const RelayLegsFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default RelayLegsFormValidation;

