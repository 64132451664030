import { useState } from 'react';

import LogoFileData from './LogoFileData';

const useLogoFileData = () => {
  const [logoFileUploadState, setLogoFileUploadState] = useState(LogoFileData.INITIAL_LOGO_FILE_UPLOAD_STATE);
  const [logoFileDownloadState, setLogoFileDownloadState] = useState(LogoFileData.INITIAL_LOGO_FILE_DOWNLOAD_STATE);

  const postLogoFile = (file) => {
    LogoFileData.postLogoFileData(file, logoFileUploadState, setLogoFileUploadState);
  };

  const getLogoFile = (fileUrl) => {
    return LogoFileData.getLogoFileData(fileUrl, logoFileDownloadState, setLogoFileDownloadState);
  };

  const getFileNameFromSavedUploadUrl = () => {
    if (logoFileUploadState.isObjLoaded === true) {
      const fileNameWithDateTime = logoFileUploadState.objData.uploadUrl.replace(LogoFileData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return logoFileUploadState.objData.uploadUrl ? decodeURI(fileName) : '';
    } else {
      return '';
    }
  };

  const getFileNameFromUrl = (url) => {
    try {
      const fileNameWithDateTime = url.replace(LogoFileData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return decodeURI(fileName) || '';
    }
    catch (e) {
      return url;
    }
  };

  return {
    logoFileUploadState,
    logoFileDownloadState,
    postLogoFile,
    getLogoFile,
    getFileNameFromSavedUploadUrl,
    getFileNameFromUrl
  };
};

export default useLogoFileData;