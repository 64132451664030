import { useState, useEffect } from 'react';
import UseForm from "../../../../common/utils/UseForm";

import validate from './RelayLegsFormValidation';

const INITIAL_OPTIONS_STATE = {
  areOptionsLoaded: false,
  options: []
};

const useRelayLegsForm = (onSubmitFormCallback, meetEventAthletes, relayLegsState, setRelayLegsState) => {
  const { formState, errorState, setFormData, onValueTextPairChanged, handleSubmit, onFormValueChanged } = UseForm({}, onSubmitFormCallback, validate);
  const [optionsState, setOptionsState] = useState(INITIAL_OPTIONS_STATE);

  useEffect(() => {
    if (formState === undefined || formState === null || Object.keys(formState).length === 0) {
      return;
    }

    const newRelayLegs = [];

    for (let i = 1; i <= 4; i++) {
      const personId = formState[`personId${i}`];
      const personName = formState[`personName${i}`];
      const legTime = formState[`legTime${i}`];
      const swimTimeRelayId = relayLegsState.relayLegs[i-1].swimTimeRelayId;
      const swimTimeId = relayLegsState.relayLegs[i-1].swimTimeId;
      const legEventId = relayLegsState.relayLegs[i-1].legEventId;
      const eventDescription = relayLegsState.relayLegs[i-1].eventDescription;

        newRelayLegs.push({
          swimTimeRelayId,
          swimTimeId,
          legNumber: i,
          personId,
          personName,
          legTime,
          legEventId,
          eventDescription
        });
    }

    setRelayLegsState({
      ...relayLegsState,
      relayLegs: newRelayLegs
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [formState]);

  useEffect(() => {
    if (meetEventAthletes === undefined || meetEventAthletes.length === 0) {
      return;
    }

    const options = [];

    meetEventAthletes.forEach((athlete) => {
      options.push({
        id: athlete.id,
        name: `${athlete.name || ''}`
      });
    });

    setOptionsState({
      ...optionsState,
      areOptionsLoaded: true,
      options
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetEventAthletes]);

  useEffect(() => {
    const numberOfLegs = 4
    if (optionsState.areOptionsLoaded === true) {
      let newFormState = {};

      if (Object.keys(formState).length === 0) {
        for (let i = 1; i <= numberOfLegs; i++) {
          newFormState[`personId${i}`] = '';
          newFormState[`personName${i}`] = '';
          newFormState[`legTime${i}`] = '';
        }

        for (const athlete of relayLegsState.relayLegs) {
          const i = athlete.legNumber;
          const targetAthlete = optionsState.options.find(x => x.id === athlete.personId);

          if (targetAthlete) {
            newFormState[`personId${i}`] = targetAthlete.id;
            newFormState[`personName${i}`] = targetAthlete.name; 
            newFormState[`legTime${i}`] = athlete.legTime;
          }
        }

        setFormData({ ...newFormState });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsState, relayLegsState]);

  return {
    formState,
    errorState,
    handleSubmit,
    onValueTextPairChanged,
    onFormValueChanged,
    options: optionsState.options || []
  };
}

export default useRelayLegsForm;