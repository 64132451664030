import React, { Fragment } from 'react';

import RelayLegsLargeGrid from './RelayLegsLargeGrid';
import RelayLegsSmallGrid from './RelayLegsSmallGrid';

const RelayLegsGrid = ({ 
  formState, 
  timeState, 
  relayLegsState, 
  options, 
  onRowChanged, 
  onValueTextPairChanged,
  onFormValueChanged }) => {
  return (
    <Fragment>
      <RelayLegsLargeGrid
        formState={formState}
        timeState={timeState}
        relayLegsState={relayLegsState}
        options={options}
        onRowChanged={onRowChanged}
        onValueTextPairChanged={onValueTextPairChanged}
        onFormValueChanged={onFormValueChanged}
      />
      <RelayLegsSmallGrid
        formState={formState}
        timeState={timeState}
        relayLegsState={relayLegsState}
        onRowChanged={onRowChanged}
        onValueTextPairChanged={onValueTextPairChanged}
        onFormValueChanged={onFormValueChanged}
      />
    </Fragment>
  )
};

export default RelayLegsGrid;