import { useState } from 'react';

import MeetDistinctAthletesWithPersonIdData from './MeetDistinctAthletesWithPersonIdData';

const useMeetDistinctAthletesWithPersonIdData = () => {
  const [meetDistinctAthletesWithPersonIdState, setMeetDistinctAthletesWithPersonIdState] = useState(MeetDistinctAthletesWithPersonIdData.INITIAL_STATE);
  const getMeetDistinctAthletesWithPersonId = (meetId, eventCompetitionGenderTypeId) => MeetDistinctAthletesWithPersonIdData.getMeetDistinctAthletesWithPersonId(meetId, eventCompetitionGenderTypeId, meetDistinctAthletesWithPersonIdState, setMeetDistinctAthletesWithPersonIdState);

  return { meetDistinctAthletesWithPersonIdState, getMeetDistinctAthletesWithPersonId };
};

export default useMeetDistinctAthletesWithPersonIdData;